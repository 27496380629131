exports.onServiceWorkerUpdateReady = () => {
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name)
      })
    })
    console.log('Complete Cache Cleared')
  }

  // const answer = window.confirm(`This application has been updated. ` + `Reload to display the latest version?`)
  // if (answer === true) {
  //   clearCacheData()
  //   window.location.reload(true)
  // }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registration successful with scope: ', registration.scope)
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error)
      })
  }
}
